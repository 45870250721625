/* You can add global styles to this file, and also import other style files */
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/saga-green/theme.css";
@import "primeng/resources/primeng.min.css";

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@600&display=swap');

body {
    background-color: var(--surface-a);
    font-family: var(--font-family);
    color: var(--text-color);
    }